<template>
  <ProductCardBuyButtonBase
    v-bind="($attrs, $props)"
    from-text-style="text-primary-default hover:text-primary-hover"
    from-amount-style="text-primary-default hover:text-primary-hover"
    ><slot
  /></ProductCardBuyButtonBase>
</template>

<script lang="ts">
import props from '~/components/domain/product-card/ProductCardBuyButtonBase.vue';

export default {
  props,
};
</script>
